import React from "react"
import Anchor from "../../core/components/anchor"
import Banner from "../../client/compositions/banner"
import Block from "../../core/components/block"
import Button from "../../client/compositions/button"
import RelatedProducts from "../../client/compositions/related-products"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import Navigation from "../../core/components/navigation"
import Overlap from "../../client/compositions/overlap"
import Row from "../../core/components/row"
import Slider from "../../core/components/slider"
import { PortfolioTaglineMenu } from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

/**
 * Project: PorscheTower Page
 * @see https://zpl.io/bzgYOWl
 */
function ProjectPorscheTowerPage() {
  return (
    <article
      id="page-portfolio-porsche-tower"
      className="page-content portfolio-page light-quick-menu"
    >
      {quickMenu}

      <Banner
        title={
          <>
            <sup aria-hidden={true} role="presentation">
              Penthouse
            </sup>
            <div>Porsche Tower</div>
          </>
        }
        content={<>Miami, Florida</>}
        className="flipped portfolio-banner"
      >
        <DecorationFluid image="portfolio-porsche-tower/banner.jpg" />
      </Banner>

      <Row id="summary" className="content-plus-media flipped">
        <Block className="block-content dropped">
          <div className="content">
            <dl className="details">
              <div className="detail">
                <dt className="title">Architect</dt>
                <dd className="content">Coble Builders (Miami, FL)</dd>
              </div>
              <div className="detail">
                <dt className="title">General Contractor</dt>
                <dd className="content">Coble Builders (Miami, FL)</dd>
              </div>
              <div className="detail">
                <dt className="title">Designer</dt>
                <dd className="content">Michael Wolk Design (Miami, FL)</dd>
              </div>
              <div className="detail">
                <dt className="title">Bottle Capacity</dt>
                <dd className="content">840</dd>
              </div>
            </dl>
            <p>
              Stunning clear acrylic shelving set in faux stone walls surrounds
              an eye-catching yet functional central glass-top island.
            </p>
          </div>
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-porsche-tower/content-01.medium.jpg" />
        </Block>
      </Row>

      <Row id="gallery-one" className="media-plus-media">
        <Block className="block-media">
          <DecorationFluid image="/portfolio-porsche-tower/content-02.medium.jpg" />
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-porsche-tower/content-03.medium.jpg" />
        </Block>
      </Row>

      <Row id="features" className="content-plus-media">
        <Block className="block-content dropped">
          <h4 className="title">Features</h4>
          <div className="content">
            <ul className="features">
              <li className="feature">
                Custom clear acrylic wine shelves, 32’ long arc shaped.
              </li>
              <li className="feature">
                Warm white LEDs, backlighting acrylic shelving.
              </li>
              <li className="feature">
                Faux stone rounded wall between each shelf.
              </li>
              <li className="feature">
                Custom wood and glass top wine storage island/table centered in
                the wine room.
              </li>
            </ul>
          </div>
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-porsche-tower/content-04.medium.jpg" />
        </Block>
      </Row>

      <Row id="gallery-two" className="single-media">
        <Block className="block-media">
          <Decoration image="/portfolio-porsche-tower/content-05.jpg" />
        </Block>
      </Row>

      <RelatedProducts
        id="related-products"
        products={[
          {
            slug: "distinct",
            title: "Distinct Collection",
            url: "products/distinct/",
          },
        ]}
      />

      <PortfolioTaglineMenu portfolioSlug="portfolio-porsche-tower" />
    </article>
  )
}

export default ProjectPorscheTowerPage
